<template>
  <div class="container-fluid">
      <div class="text-left" cols="12" v-show="showAnalisis">
        <p class="mt-1 fs20 bold">{{ $t('analisis.txt_1') }}</p>
        <p class="fs16">{{ $t('analisis.txt_2') }}</p>
      </div>
      <div v-if="showModule">
        <filtros-analisis v-show="showAnalisis"/>
        <resultados v-if="!showAnalisis"/>
        <v-card class="mt-5">
          <tabla v-if="showAnalisis"/>
        </v-card>
      </div>
    <!-- OVERLAY -->
    <v-overlay opacity=".70" :value="loadingModule.active" style="z-index: 999999 !important;">
      <div class="loader">
        <div id="dot1"></div>
        <div id="dot2"></div>
        <div id="dot3"></div>
        <div id="dot4"></div>
        <div id="dot5"></div>
        <div id="dot6"></div>
        <div id="dot7"></div>
        <div id="dot8"></div>
        <div id="dot9"></div>
        <div id="dot10"></div>
        <div id="base1"></div>
        <div id="base2"></div>
        <div id="base3"></div>
        <div id="base4"></div>
        <div id="base5"></div>
        <div class="loader-content">
          <p class="p-body">{{ loadingModule.text }}</p>
        </div>
      </div>
    </v-overlay>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';
import FiltrosAnalisis from './FiltrosAnalisis.vue';
import Tabla from './Tabla.vue';
import Resultados from './Resultados.vue';

export default {
  components: {
    FiltrosAnalisis,
    Tabla,
    Resultados,
  },
  data() {
    return {
      showModule: false,
    };
  },
  computed: {
    ...mapState('storeInterpretacion', ['loadingModule', 'showAnalisis', 'infoUser', 'isLab']),
  },
  async created() {
    await this.getCatalogs().then((response) => {
      this.actionCatalogs(response.data.data);
      // this.infoUser.rol_id = 2;
      if (!this.isLab || (this.infoUser && /* this.infoUser.rol_id !== 2 && */ this.isLab)) {
        this.actionShowEditResults(true);
      }
      this.showModule = true;
    }).catch((error) => {
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        text: this.$t('analisis.an_error_occurred'),
      });
    })
      .finally(() => {
        this.actionLoadingModule();
      });
  },
  methods: {
    ...mapActions('storeInterpretacion', ['actionLoadingModule', 'actionCatalogs', 'actionShowEditResults']),
    async getCatalogs() {
      this.actionLoadingModule({ text: this.$t('analisis.loading'), active: true });
      return new Promise(async (resolve, reject) => {
        await axios.post(`${process.env.VUE_APP_URL_API}api/get-data-analisis`, null, {
          headers: {
            Authorization: `Bearer ${this.$ls.storage.token}`,
          },
        }).then((response) => {
          try {
            if (response.data.status === 'fail') {
              throw new Error(response.data.error);
            }
            resolve(response);
          } catch (error) {
            reject(response);
          }
        })
          .catch((error) => {
            try {
              throw new Error(error.response.data.error);
            } catch (error) {
              reject(error);
            }
          });
      });
    },
    displayTextNiveles(nivel) {
      let text_translated = nivel;
      switch (nivel) {
        case 'Materia Prima':
          text_translated = this.$t('submatriz.MateriaPrima');
          break;
        case 'Semi Elaborado':
          text_translated = this.$t('submatriz.SemiElaborado');
          break;
        case 'Producto Terminado':
          text_translated = this.$t('submatriz.ProductoTerminado');
          break;
        case 'Leches Y Productos Lacteos':
          text_translated = this.$t('analisis.lechesyproductoslacteos');
          break;
        case 'Helados Y Mezclas Para Helados':
          text_translated = this.$t('analisis.heladosymezclasparahelados');
          break;
        case 'Productos Grasos':
          text_translated = this.$t('analisis.productosgrasos');
          break;
        case 'Caldos, Sopas, Cremas Y Mezclas Deshidratadas':
          text_translated = this.$t('analisis.caldossopascremasymezclasdeshidratadas');
          break;
        case 'Productos Elaborados A Partir De Cereales':
          text_translated = this.$t('analisis.productoselaboradosapartirdecereales');
          break;
        case 'Azucares Y Miel':
          text_translated = this.$t('analisis.azucaresymiel');
          break;
        case 'Productos De Confiteria':
          text_translated = this.$t('analisis.productosdeconfiteria');
          break;
        case 'Productos De Panaderia Y Pasteleria':
          text_translated = this.$t('analisis.productosdepanaderiaypasteleria');
          break;
        case 'Alimentos De Uso Infantil':
          text_translated = this.$t('analisis.alimentosdeusoinfantil');
          break;
        case 'Carnes Y Productos Carneos':
          text_translated = this.$t('analisis.carnesyproductoscarneos');
          break;
        case 'Pescados Y Productos De La Pesca':
          text_translated = this.$t('analisis.pescadosyproductosdelapesca');
          break;
        case 'Huevos Y Ovoproductos':
          text_translated = this.$t('analisis.huevosyovoproductos');
          break;
        case 'Salsas, Aderezos, Especias Y Condimentos':
          text_translated = this.$t('analisis.salsasaderezosespeciasycondimentos');
          break;
        case 'Frutas Y Verduras':
          text_translated = this.$t('analisis.frutasyverduras');
          break;
        case 'Comidas Y Platos Preparados':
          text_translated = this.$t('analisis.comidasyplatospreparados');
          break;
        case 'Bebidas Y Aguas':
          text_translated = this.$t('analisis.bebidasyaguas');
          break;
        case 'Estimulantes Y Fruitivos':
          text_translated = this.$t('analisis.estimulantesyfruitivos');
          break;
        case 'Conservas':
          text_translated = this.$t('analisis.conservas');
          break;
        case 'No Aplica':
          text_translated = this.$t('analisis.noaplica');
          break;
        case 'Zona 1':
          text_translated = this.$t('analisis.zona_1');
          break;
        case 'Zona 2':
          text_translated = this.$t('analisis.zona_2');
          break;
        case 'Zona 3':
          text_translated = this.$t('analisis.zona_3');
          break;
        case 'Critica':
          text_translated = this.$t('analisis.critica');
          break;
        case 'No Critica':
          text_translated = this.$t('analisis.no_critica');
          break;
        case 'Pre-Sanitización':
          text_translated = this.$t('analisis.pre_sanitizacion');
          break;
        case 'Post-Sanitización':
          text_translated = this.$t('analisis.post_sanitizacion');
          break;
        case 'En Uso':
          text_translated = this.$t('analisis.en_uso');
          break;
        case 'Con Guantes':
          text_translated = this.$t('analisis.con_guantes');
          break;
        case 'Agua de Tratamiento':
          text_translated = this.$t('analisis.agua_de_tratamiento');
          break;
        case 'Agua de saneo':
          text_translated = this.$t('analisis.agua_de_saneo');
          break;
        case 'Agua de proceso':
          text_translated = this.$t('analisis.agua_de_proceso');
          break;
        default:
          return text_translated;
      }

      return text_translated;
    },
    transMatrices(matriz) {
      return matriz ? this.$t(`analisis.${matriz}`) : '-';
    },
  },
};
</script>
<style lang="scss" scoped>
.container-fluid {
  margin: 50px 45px;
}
</style>
