<style>
.vue-treeselect {
  width: 300px; /* Ajusta el ancho según tus necesidades */
  position: relative; /* Necesario para que el menú se posicione respecto al contenedor */
}
.vue-treeselect__menu {
  max-height: 200px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto; /* Habilita el desplazamiento si hay muchas opciones */
  position: absolute;
  z-index: 1000; /* Asegúrate de que el menú esté por encima de otros elementos */
  top: 100%; /* Posiciona el menú justo debajo del input */
  left: 0;
  width: auto; /* Asegura que el ancho se ajuste al contenido */
}
.vue-treeselect__list {
  max-width: 300px; /* Ajusta el ancho máximo del dropdown */
  max-height: 400px; /* Ajusta la altura máxima del dropdown */
  overflow-y: scroll; /* Para poder hacer scroll si hay muchas opciones */
}

.custom-treeselect {
  width: 300px;
}

.custom-treeselect .vue-treeselect__menu {
  max-height: 250px;
}
</style>
<template>
  <div>
    <v-row v-if="tipoInterpretacion === 4 && pocilloDinamic">
      <v-col cols="12">
        <v-simple-table class="table-vue-tree">
          <template v-slot:default>
            <thead>
            <tr>
              <th v-for="(head,indexHead) in headersPocillos" v-if="head.showAuto" :key="indexHead">
                {{ head.text }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-start">
                <v-select
                    :items="optionsSampleGeneric"
                    v-model="pocilloDinamic.prefijo_muestra"
                    :placeholder="$t('analisis.tipo_muestra')"
                    item-value="id"
                    item-text="text"
                    outlined
                    dense
                    @change="changeTipeSampleDinamic($event)"
                    append-icon="mdi-chevron-down"
                ></v-select>
              </td>
              <td class="text-start">
                <span v-if="pocilloDinamic.is_control === 1 || pocilloDinamic.muestra_id === null">-</span>
                <v-select
                    v-else
                    :items="matrices"
                    :placeholder="$t('analisis.seleccionar_matriz')"
                    item-value="id"
                    :item-text="displayTextMatrices"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    v-model="pocilloDinamic.matriz_id"
                    @change="changeMatrizSelectedDinamic()"
                ></v-select>
              </td>
              <td class="text-start">
                <span v-if="pocilloDinamic.is_control === 1 || pocilloDinamic.muestra_id === null">-</span>
                <v-select
                    v-else
                    :items="getEnsayosPrograma(pocilloDinamic)"
                    :placeholder="$t('analisis.seleccionar_ensayo')"
                    item-text="name_ensayo"
                    item-value="grupo_ensayo_id"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    v-model="pocilloDinamic.grupo_ensayo_id"
                    @change="changeEnsayoSelectedDinamic(pocilloDinamic)"
                ></v-select>
              </td>
              <td class="text-start">
                <span v-if="pocilloDinamic.muestra_id === null">-</span>
                <v-select v-else class="custom-select o-select" outlined dense v-model="pocilloDinamic.mix_id"
                          :placeholder="$t('analisis.mix')" item-text="mixname"
                          item-value="mix_id" :items="getMixesEnsayo(pocilloDinamic)"
                          @change="changeMixSelectedDinamic(pocilloDinamic)"
                >
                  <option :value="undefined" disabled style="display: none">
                    {{ $t('analisis.mix') }}
                  </option>
                </v-select>
              </td>
              <td class="text-start">
                <span v-if="pocilloDinamic.muestra_id === null || programaSelected != 2">-</span>
                <v-select
                    v-else
                    :items="getMediosEnsayo(pocilloDinamic)"
                    :placeholder="$t('analisis.seleccionar_medio')"
                    item-value="id"
                    item-text="nombre"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    v-model="pocilloDinamic.medio_id"
                    @change="changeMedioSelected(pocilloDinamic)"
                ></v-select>
              </td>
              <td class="text-start" width="200px">
                <span v-if="pocilloDinamic.is_control === 1 || pocilloDinamic.muestra_id === null || (pocilloDinamic.muestra_id !== null && programaSelected != 2)">-</span>
                <Treeselect
                    v-else
                    :options="getCatalogosTreeMatriz(pocilloDinamic)"
                    @select="handleNodeSelect($event,pocilloDinamic)"
                    :normalizer="normalizer"
                    :placeholder="$t('analisis.selecciona_categoria')"
                    :clearable="false"
                    :searchable="false"
                    append-to-body
                    class="custom-treeselect"
                >
                  <label slot="option-label" slot-scope="{node}">
                    {{ $parent.$parent.displayTextNiveles(node.label)}}
                  </label>
                  <div slot="value-label" slot-scope="{ node }">{{ pocilloDinamic.nivel_muestra_names ? formatBranchTree(pocilloDinamic) : $t('analisis.selecciona_categoria') }}</div>
                </Treeselect>
              </td>
              <td class="text-start">
                <span v-if="pocilloDinamic.is_control === 1 || pocilloDinamic.muestra_id === null || (pocilloDinamic.muestra_id !== null && programaSelected != 2)">-</span>
                <v-select
                    v-else
                    :items="tipo_deteriorantes"
                    :placeholder="$t('analisis.seleccionar_sample_status')"
                    item-value="id"
                    :item-text="displayTextTipoDeteriorantes"
                    outlined
                    dense
                    append-icon="mdi-chevron-down"
                    v-model="pocilloDinamic.deteriorado"
                >
                </v-select>
              </td>
              <td class="text-start">
                {{ pocilloDinamic.is_control === 0 ? getTxtRange(pocilloDinamic.txt_range) : '-' }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <v-btn class="float-right mx-7 c-btn" color="#007AFF" outlined @click="editPocillos()">
          {{ $t('analisis.agregar_pocillos') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        class="o-table-interpretation table-vue-tree" dense fixed-header
        v-model="pocillosSelected"
        :headers="headersPocillos"
        :items="pocillos_interpretacion"
        :single-select="false"
        item-key="coordinate"
        :show-select="tipoInterpretacion === 4"
        disable-pagination
    >
      <template v-slot:body="{ items, isSelected, select}">
        <tbody>
        <tr v-for="(pocillo, index) in pocillos_interpretacion" :key="index">
          <td v-if="tipoInterpretacion === 4">
            <v-simple-checkbox
                :ripple="false"
                :value="isSelected(pocillo)"
                style="margin:0px;padding:0px"
                color="#535353"
                hide-details
                @input="select(pocillo,!isSelected(pocillo))"
            >
            </v-simple-checkbox>
          </td>
          <td class="text-start">
            <span>
              {{`${pocillo.coordinate} (${pocillo.position})`}}
            </span>
          </td>
          <td class="text-start">
              <span v-if="tipoInterpretacion === 3">
                {{ pocillo.is_control === 0 ? pocillo.muestra_id  : pocillo.prefijo_muestra}}
              </span>
            <v-select
                v-else
                :items="optionsSampleGeneric"
                v-model="pocillo.prefijo_muestra"
                :placeholder="'Tipo muestra'"
                item-value="id"
                item-text="text"
                outlined
                dense
                @change="changeTipeSample($event,pocillo)"
                append-icon="mdi-chevron-down"
            ></v-select>
          </td>
          <td class="text-start">
              <span v-if="tipoInterpretacion === 3">
                {{ pocillo.matriz_name ? $parent.$parent.transMatrices(pocillo.matriz_name) : '-' }}
              </span>
            <div v-else>
              <span v-if="pocillo.is_control === 1 || pocillo.muestra_id === null">-</span>
              <v-select
                  v-else
                  :items="matrices"
                  :placeholder="$t('analisis.seleccionar_matriz')"
                  item-value="id"
                  :item-text="displayTextMatrices"
                  outlined
                  dense
                  append-icon="mdi-chevron-down"
                  v-model="pocillo.matriz_id"
                  @change="changeMatrizSelected(pocillo)"
              ></v-select>
            </div>

          </td>
          <td class="text-start">
              <span v-if="tipoInterpretacion === 3">
                {{ getEnsayo(pocillo.grupo_ensayo_id) }}
              </span>
            <div v-else>
              <span v-if="pocillo.is_control === 1 || pocillo.muestra_id === null">-</span>
              <v-select
                  v-else
                  :items="getEnsayosPrograma(pocillo)"
                  :placeholder="$t('analisis.seleccionar_ensayo')"
                  item-text="name_ensayo"
                  item-value="grupo_ensayo_id"
                  outlined
                  dense
                  append-icon="mdi-chevron-down"
                  v-model="pocillo.grupo_ensayo_id"
                  @change="changeEnsayoSelected(pocillo)"
              ></v-select>
            </div>
          </td>
          <td class="text-start">
              <span v-if="tipoInterpretacion === 3">
                {{ pocillo.mix_prefix ? pocillo.mix_prefix : '-' }}
              </span>
            <div v-else>
              <span v-if="pocillo.muestra_id === null">-</span>
              <v-select v-else class="custom-select o-select" outlined dense v-model="pocillo.mix_id"
                        :placeholder="'Mix'" item-text="mixname"
                        item-value="mix_id" :items="getMixesEnsayo(pocillo)"
                        @change="changeMixSelected(pocillo)"
              >
                <option :value="undefined" disabled style="display: none">
                  {{ 'Mix' }}
                </option>
              </v-select>
            </div>
          </td>
          <td class="text-start">
              <span v-if="tipoInterpretacion === 3">
                {{ pocillo.medio_name ? pocillo.medio_name : '-' }}
              </span>
            <div v-else>
              <span v-if="pocillo.muestra_id === null || programaSelected != 2">-</span>
              <v-select
                  v-else
                  :items="getMediosEnsayo(pocillo)"
                  :placeholder="$t('analisis.seleccionar_medio')"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  dense
                  append-icon="mdi-chevron-down"
                  v-model="pocillo.medio_id"
                  @change="changeMedioSelected(pocillo)"
              ></v-select>
            </div>
          </td>
          <td class="text-start" width="200px">
              <span v-if="tipoInterpretacion === 3">
                {{ formatBranch(pocillo.nivel_muestra_names) }}
              </span>
            <div v-else>
              <span v-if="pocillo.is_control === 1 || pocillo.muestra_id === null || programaSelected != 2">-</span>
              <Treeselect
                  v-else
                  :ref="`tree_select_${pocillo.position}`"
                  :options="getCatalogosTreeMatriz(pocillo)"
                  @select="handleNodeSelect($event,pocillo)"
                  :normalizer="normalizer"
                  :placeholder="$t('analisis.selecciona_categoria')"
                  :clearable="false"
                  :searchable="false"
                  class="vue-tree-min-width"
              >
                <label slot="option-label" slot-scope="{node}">
                  {{ $parent.$parent.displayTextNiveles(node.label)}}
                </label>
                <div slot="value-label" slot-scope="{ node }">{{ pocillo.nivel_muestra_names ? formatBranchTree(pocillo) : $t('analisis.selecciona_categoria') }}</div>
              </Treeselect>
            </div>
          </td>
          <td class="text-start">
              <span v-if="tipoInterpretacion === 3">
                {{ getDeteriorado(pocillo.deteriorado) }}
              </span>
            <div v-else>
              <span v-if="pocillo.is_control === 1 || pocillo.muestra_id === null || programaSelected != 2">-</span>
              <v-select
                  v-else
                  :items="tipo_deteriorantes"
                  :placeholder="$t('analisis.seleccionar_sample_status')"
                  item-value="id"
                  :item-text="displayTextTipoDeteriorantes"
                  outlined
                  dense
                  append-icon="mdi-chevron-down"
                  v-model="pocillo.deteriorado"
              >
              </v-select>
            </div>
          </td>
          <td class="text-start">
            {{ pocillo.is_control === 0 ? getTxtRange(pocillo.txt_range) : '-' }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Swal from 'sweetalert2';

export default {
  components: {
    Treeselect,
  },
  data() {
    return {
      optionsSampleGeneric: [
        { value: null, id: null, text: this.$t('analisis.vacio') },
        { value: 'sample', id: 'Mu', text: this.$t('analisis.muestra') },
        { value: 0, id: 'PC', text: 'PC' },
        { value: 0, id: 'NC', text: 'NC' },
        { value: 0, id: 'PEC', text: 'PEC' },
        { value: 0, id: 'NEC', text: 'NEC' },
        { value: 0, id: 'PMC', text: 'PMC' },
        { value: 0, id: 'NMC', text: 'NMC' },
      ],
    };
  },
  computed: {
    ...mapState('storeInterpretacion', ['pocillos_interpretacion', 'tipoInterpretacion', 'matrices',
      'ensayos_software', 'mixes', 'mixes_controles', 'medios_ensayos', 'niveles_muestras', 'tipo_deteriorantes', 'sampleTemplate', 'controles',
      'programaSelected', 'canalesSoftwares', 'softwares', 'equipoLaboratorioSelected', 'pocillosSelected', 'pocilloDinamic',
    ]),
    getEnsayo() {
      return (grupo_ensayo_id) => {
        let ensayoTxt = '-';
        const findEnsayo = this.ensayos_software.find((ensa) => ensa.grupo_ensayo_id == grupo_ensayo_id);
        if (findEnsayo) {
          ensayoTxt = findEnsayo.name_ensayo;
        }
        return ensayoTxt;
      };
    },
    getEnsayosPrograma() {
      return (sample) => {
        let assays = [];
        if (this.programaSelected) {
          assays = this.ensayos_software
            .filter((assay) => {
              let findEnsayo = true;
              if (sample.is_control === 0) {
                findEnsayo = assay.ensayos_matriz.some((ensa) => ensa.matriz_id == sample.matriz_id);
              }
              return parseInt(assay.id_programa) === parseInt(this.programaSelected) && findEnsayo;
            });
        }
        return assays;
      };
    },
    getMixesEnsayo() {
      return (sample) => {
        let mixes_ensayo = [];
        if (sample.is_control === 0) {
          if (sample.grupo_ensayo_id > 0) {
            mixes_ensayo = this.mixes.filter((mix) => parseInt(mix.grupo_ensayo_id) === parseInt(sample.grupo_ensayo_id) && parseInt(mix.id_programa) === parseInt(this.programaSelected));
          }
        } else {
          mixes_ensayo = this.mixes_controles.filter((mix) => parseInt(mix.id_programa) === parseInt(this.programaSelected));
        }

        return mixes_ensayo;
      };
    },
    getMediosEnsayo() {
      return (sample) => {
        let medios = [];
        /* if (sample.grupo_ensayo_id > 0) {
          medios = this.medios_ensayos.filter((medio) => medio.id_grupo_ensayo == sample.grupo_ensayo_id);
        } */
        if (sample.mix_id) {
          medios = this.medios_ensayos.filter((medio) => parseInt(medio.mix_id) === parseInt(sample.mix_id));
        }
        return medios;
      };
    },
    getCatalogosTreeMatriz() {
      return (sample) => {
        const filterCatalogos = this.niveles_muestras.filter((tree) => parseInt(tree.matriz_id) === parseInt(sample.matriz_id));
        if (filterCatalogos.length > 0) return filterCatalogos[0].catalogos;
        return [];
      };
    },
    headersPocillos() {
      return [
        {
          text: this.$t('analisis.well'), showAuto: false, filterable: false, value: 'coordinate',
        },
        {
          text: this.$t('analisis.codigo_muestra'), showAuto: true, filterable: false, value: 'muestra_id',
        },
        {
          text: this.$t('analisis.matriz'), showAuto: true, filterable: false, value: 'matriz_id',
        },
        {
          text: this.$t('analisis.ensayo'), showAuto: true, filterable: false, value: 'grupo_ensayo_id',
        },
        {
          text: 'Mix', showAuto: true, filterable: false, value: 'mix_id',
        },
        {
          text: this.$t('analisis.medio'), showAuto: true, filterable: false, value: 'medio_id',
        },
        {
          text: this.$t('analisis.categoria'), showAuto: true, filterable: false, value: 'nivel_muestra',
        },
        {
          text: this.$t('analisis.estado_muestra'), showAuto: true, filterable: false, value: 'deteriorado',
        },
        {
          text: this.$t('analisis.tipo_muestra'), showAuto: true, filterable: false, value: 'type_semicuantificado',
        },
      ];
    },
    pocillosSelected: {
      get() { return this.$store.state.storeInterpretacion.pocillosSelected; },
      set(value) { this.actionPocillosSelected(value); },
    },
  },
  methods: {
    ...mapActions('storeInterpretacion', ['actionArchivos_requeridos', 'actionMaxFiles',
      'actionPocillosSelected', 'actionPocilloDinamic']),
    displayTextMatrices(item) {
      return this.$t(`analisis.${item.matriz}`);
    },
    displayTextTipoDeteriorantes(item) {
      return this.$t(`analisis.${item.name}`);
    },

    getTxtRange(txt_range) {
      // console.log({getTxtRange:txt_range})
      if (txt_range === 'directa') {
        return this.$t('analisis.directa');
      }
      return txt_range || '-';
    },
    getDeteriorado(deteriorado) {
      switch (deteriorado) {
        case 'DETERIORADO': return this.$t('analisis.deteriorado'); break;
        case 'NO DETERIORADO': return this.$t('analisis.nodeteriorado'); break;
        default: return '-';
      }
    },
    getSampleTemplate() {
      return structuredClone(this.sampleTemplate);
    },
    async changeTipeSample(event, pocillo) {
      console.log({ event, pocillo });
      // this.actionArchivos_requeridos(null);
      // this.actionMaxFiles(0);
      pocillo.ot = null;
      pocillo.medio_id =	null;
      pocillo.medio_prefix =	null;
      pocillo.medio_name =	null;
      pocillo.muestra_id =	null;
      pocillo.ensayo_id =	null;
      pocillo.grupo_ensayo_id =	null;
      pocillo.grupo_ensayo_mix =	null;
      pocillo.ensayo_muestra_id =	null;
      pocillo.ensayo_easypcr_id =	null;
      pocillo.matriz_id =	null;
      pocillo.descripcion =	null;
      pocillo.deteriorado =	null;
      pocillo.mix_id =	null;
      pocillo.mix_prefix =	null;
      pocillo.nivel_muestra =	null;
      pocillo.nivel_muestra_names =	null;
      pocillo.type_semicuantificado =	0;
      pocillo.is_control =	0;
      pocillo.control_id =	null;
      pocillo.bitacora_muestra_id =	null;
      pocillo.uid =	null;
      pocillo.parametros_interpretacion =	null;
      pocillo.txt_range = null;
      pocillo.matriz_name = null;
      pocillo.m_matriz = 'otro';
      pocillo.m_freq = 'Otro';
      pocillo.ensayo_name = null;
      pocillo.zona = null;
      pocillo.cliente_nombre = null;
      pocillo.local_nombre = null;
      pocillo.cliente_id_muestra = 0;
      pocillo.file_resultado = null;
      pocillo.control_muestra_ot = null;
      pocillo.muestra_id = null;
      const findPocillo = await this.pocillos_interpretacion.find((pi) => pi.coordinate === pocillo.coordinate);
      console.log({ findPocillo });
      if (!findPocillo) {
        await this.getFilesRequiredBitacoraManual();
        return;
      }
      if (event === null) {
        await this.getFilesRequiredBitacoraManual();
        return;
      }
      findPocillo.ot = 0;
      findPocillo.ensayo_muestra_id = 0;
      findPocillo.nivel_muestra = '0-0-0-0-0-0-0';
      findPocillo.txt_range = 'directa';
      switch (event) {
        case 'Mu':
          findPocillo.muestra_id = 0;
          findPocillo.descripcion = `sample_${findPocillo.coordinate}`;
          break;
        default:
          const getControl = await this.controles.find((control) => control.control_prefix === event);
          console.log({ getControl });
          if (getControl) {
            findPocillo.is_control = 1;
            findPocillo.control_id =	getControl.control_id;
            findPocillo.muestra_id = 0;

            const mixDefault = this.getMixesEnsayo(findPocillo);
            console.log({ mixDefault });
            if (mixDefault.length === 1) {
              findPocillo.mix_id = mixDefault[0].mix_id;
              await this.changeMixSelected(findPocillo);
            }
          }
      }
      await this.getFilesRequiredBitacoraManual();
      console.log({ changeTipeSample: findPocillo });
    },
    async changeMatrizSelected(pocillo) {
      // this.actionArchivos_requeridos(null);
      // this.actionMaxFiles(0);
      if (!pocillo.matriz_id) {
        await this.getFilesRequiredBitacoraManual();
        return;
      }
      pocillo.nivel_muestra = '0-0-0-0-0-0-0';
      pocillo.nivel_muestra_names = null;
      pocillo.grupo_ensayo_id = null;
      pocillo.ensayo_id = null;
      pocillo.ensayo_easypcr_id = null;
      pocillo.ensayo_name = null;

      const getMatriz = this.matrices.find((mat) => mat.id == pocillo.matriz_id);
      if (getMatriz) {
        pocillo.matriz_name = getMatriz.matriz;
        await this.getFilesRequiredBitacoraManual();
        return;
      }
      pocillo.matriz_id = null;
      pocillo.matriz_name = null;
      await this.getFilesRequiredBitacoraManual();
    },
    async changeEnsayoSelected(pocillo) {
      // await this.getFilesRequiredBitacoraManual();
      // this.actionArchivos_requeridos(null);
      // this.actionMaxFiles(0);
      pocillo.medio_id = null;
      pocillo.medio_prefix =	null;
      pocillo.medio_name =	null;
      pocillo.mix_id = null;
      pocillo.grupo_ensayo_mix = null;
      pocillo.ensayo_name = null;
      if (!pocillo.grupo_ensayo_id) {
        await this.getFilesRequiredBitacoraManual();
        return;
      }
      const findEnsayo = this.getEnsayosPrograma(pocillo).find((ensa) => ensa.grupo_ensayo_id == pocillo.grupo_ensayo_id);
      if (findEnsayo) {
        let ensayoSelected = null;
        if (pocillo.is_control === 1) {
          /* const ensayoSelected = findEnsayo.ensayos_matriz[0];
          pocillo.ensayo_id = ensayoSelected.ensayo_id;
          pocillo.ensayo_easypcr_id = ensayoSelected.ensayo_easypcr_id;
          pocillo.ensayo_name = findEnsayo.name_ensayo; */
          await this.getFilesRequiredBitacoraManual();
          return;
        }
        ensayoSelected = findEnsayo.ensayos_matriz.find((ensa) => ensa.matriz_id == pocillo.matriz_id);
        if (ensayoSelected) {
          pocillo.ensayo_id = ensayoSelected.ensayo_id;
          pocillo.ensayo_easypcr_id = ensayoSelected.ensayo_easypcr_id;
          pocillo.ensayo_name = findEnsayo.name_ensayo;
          const mixDefault = this.getMixesEnsayo(pocillo);
          console.log({ mixDefault });
          if (mixDefault.length === 1) {
            pocillo.mix_id = mixDefault[0].mix_id;
            await this.changeMixSelected(pocillo);
          }
          await this.getFilesRequiredBitacoraManual();
          return;
        }
      }
      pocillo.grupo_ensayo_id = null;
      pocillo.ensayo_id = null;
      pocillo.ensayo_easypcr_id = null;
      pocillo.ensayo_name = null;
      await this.getFilesRequiredBitacoraManual();
    },
    async changeMixSelected(pocillo) {
      // this.actionArchivos_requeridos(null);
      // this.actionMaxFiles(0);
      if (!pocillo.mix_id) {
        await this.getFilesRequiredBitacoraManual();
        return;
      }

      let getMix = null;
      if (pocillo.is_control) {
        getMix = this.mixes_controles.find((mix) => parseInt(mix.id_programa) === parseInt(this.programaSelected)
            && parseInt(mix.mix_id) == parseInt(pocillo.mix_id));
      } else {
        getMix = this.mixes.find((mix) => mix.grupo_ensayo_id == pocillo.grupo_ensayo_id
            && mix.id_programa === this.programaSelected && mix.mix_id == pocillo.mix_id);
      }
      pocillo.medio_id = 0;
      if (getMix) {
        pocillo.mix_prefix = getMix.mixname;

        const medioDefault = this.getMediosEnsayo(pocillo);
        if (medioDefault.length && this.programaSelected == 2) {
          pocillo.medio_id = medioDefault[0].id;
          await this.changeMedioSelected(pocillo);
        }
        // pocillo.grupo_ensayo_mix = getMix.grupo_ensayo_mix;
        await this.getFilesRequiredBitacoraManual();
        return;
      }
      pocillo.mix_id = null;
      pocillo.grupo_ensayo_mix = null;
      await this.getFilesRequiredBitacoraManual();
    },
    async changeMedioSelected(pocillo) {
      if (!pocillo.medio_id) {
        return;
      }
      const getMedio = this.medios_ensayos.find((medio) =>
      /* medio.id_grupo_ensayo == pocillo.grupo_ensayo_id && */ pocillo.medio_id == medio.id);
      if (getMedio) {
        pocillo.medio_id = getMedio.id;
        pocillo.medio_prefix =	getMedio.prefijo;
        pocillo.medio_name =	getMedio.nombre;
        return;
      }
      pocillo.medio_id = null;
      pocillo.medio_prefix =	null;
      pocillo.medio_name =	null;
    },
    normalizer(node) {
      return {
        id: node.key,
        id_cat: node.id,
        ruta: node.ruta,
        table: node.table,
        label: node.name,
        children: node.children,
      };
    },
    handleNodeSelect(node, pocillo) {
      console.log({ handleNodeSelect: node });
      if(node === undefined){ return;}
      const nodeSelected = {
        id: node.id, name: node.name, table: node.table, ruta: node.ruta, key: node.key,
      };
      console.log({ nodeSelected });
      pocillo.nivel_muestra = node.ruta.ids;
      pocillo.nivel_muestra_names = node.ruta;
    },
    formatBranch(branch) {
      if (branch === null) {
        return '-';
      }
      const route = branch;
      const routeParts = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
      let formattedRoute = '';
      for (const part of routeParts) {
        if (route.hasOwnProperty(part)) { formattedRoute += (part === 'A' ? '' : '/') + this.$parent.$parent.displayTextNiveles(route[part]); }
      }
      return formattedRoute;
    },
    formatBranchTree(pocillo) {
      if (pocillo.nivel_muestra_names === null) {
        return '-';
      }
      const route = pocillo.nivel_muestra_names;
      console.log({ route });
      const routeParts = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
      let formattedRoute = '';
      for (const part of routeParts) {
        if (route.hasOwnProperty(part)) { formattedRoute += (part === 'A' ? '' : '/') + this.$parent.$parent.displayTextNiveles(route[part]); }
      }
      return formattedRoute;
    },
    async getFilesRequiredBitacoraManual() {
      this.actionArchivos_requeridos(null);
      this.actionMaxFiles(0);

      const pocillosBitacora = structuredClone(this.pocillos_interpretacion);
      const pocillosLlenos = await pocillosBitacora
        .filter((pocillo) => pocillo.grupo_ensayo_id !== null
              && pocillo.mix_id !== null);
      console.warn({pocillosLlenos})
      if (!pocillosLlenos.length) {
        /* Swal.fire({
          icon: 'warning',
          title: this.$t('analisis.warning'),
          text: this.$t('analisis.no_muestras_bitacora'),
        }); */
        return;
      }
      let canales_not_found = [];
      let filesNecessary = [];
      let software_not_found = [];
      for await (const pocillo of pocillosLlenos) {
        const grupoEnsayoId = pocillo.grupo_ensayo_id;
        const mixId = pocillo.mix_id;
        const getCanalesNeeded = await this.canalesSoftwares
          .filter((canal) => canal.equipo_id === this.equipoLaboratorioSelected.equipo_id
                && canal.grupo_ensayo_id === grupoEnsayoId);
        if (!getCanalesNeeded.length) {
          canales_not_found.push(JSON.stringify({ equipo_id: this.equipoLaboratorioSelected.equipo_id, grupo_ensayo_id: grupoEnsayoId }));
        }
        for await (const canal of getCanalesNeeded) {
          const canalClone = structuredClone(canal);
          delete canalClone.grupo_ensayo_id;
          delete canalClone.equipo_id;
          filesNecessary.push(JSON.stringify(canalClone));
        }
        const getSoftwareEnsayos = await this.softwares
          .filter((soft) => soft.equipo_id === this.equipoLaboratorioSelected.equipo_id
                && soft.mix_id === mixId
                && soft.grupo_ensayo_id === grupoEnsayoId);
        if (!getSoftwareEnsayos.length) {
          software_not_found.push(JSON.stringify({ equipo_id: this.equipoLaboratorioSelected.equipo_id, grupo_ensayo_id: grupoEnsayoId }));
        }
      }

      canales_not_found = [...new Set(canales_not_found)].map((m) => JSON.parse(m));
      filesNecessary = [...new Set(filesNecessary)].map((m) => JSON.parse(m));
      software_not_found = [...new Set(software_not_found)].map((m) => JSON.parse(m));
      if (canales_not_found.length) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('analisis.warning'),
          text: this.$t('analisis.some_assays_cant_use_equipment'),
        });
        return;
      }
      if (!filesNecessary.length) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('analisis.warning'),
          text: this.$t('analisis.not_found_canales'),
        });
        return;
      }
      if (software_not_found.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'some_assays_has_not_software_to_this_equipment',
        });
        return;
      }
      this.actionArchivos_requeridos(filesNecessary);
      this.actionMaxFiles(filesNecessary.length);
    },
    async changeTipeSampleDinamic(event) {
      this.actionPocilloDinamic(this.getSampleTemplate());
      if (event === null) {
        return;
      }
      this.pocilloDinamic.prefijo_muestra = event;
      this.pocilloDinamic.ot = 0;
      this.pocilloDinamic.ensayo_muestra_id = 0;
      this.pocilloDinamic.nivel_muestra = '0-0-0-0-0-0-0';
      this.pocilloDinamic.txt_range = 'directa';
      switch (event) {
        case 'Mu':
          this.pocilloDinamic.muestra_id = 0;
          this.pocilloDinamic.descripcion = 'sample';
          break;
        default:
          const getControl = await this.controles.find((control) => control.control_prefix === event);
          console.log({ getControl });
          if (getControl) {
            this.pocilloDinamic.is_control = 1;
            this.pocilloDinamic.control_id =	getControl.control_id;
            this.pocilloDinamic.muestra_id = 0;

            const mixDefault = this.getMixesEnsayo(this.pocilloDinamic);
            console.log({ mixDefault });
            if (mixDefault.length === 1) {
              this.pocilloDinamic.mix_id = mixDefault[0].mix_id;
              await this.changeMixSelectedDinamic(this.pocilloDinamic);
            }
          }
      }

      // console.log({changeTipeSampleDinamic:this.pocilloDinamic});
    },
    async changeMatrizSelectedDinamic() {
      if (!this.pocilloDinamic.matriz_id) {
        return;
      }
      this.pocilloDinamic.nivel_muestra = '0-0-0-0-0-0-0';
      this.pocilloDinamic.nivel_muestra_names = null;
      this.pocilloDinamic.grupo_ensayo_id = null;
      this.pocilloDinamic.ensayo_id = null;
      this.pocilloDinamic.ensayo_easypcr_id = null;
      this.pocilloDinamic.ensayo_name = null;

      const getMatriz = this.matrices.find((mat) => mat.id == this.pocilloDinamic.matriz_id);
      if (getMatriz) {
        this.pocilloDinamic.matriz_name = getMatriz.matriz;
        return;
      }
      this.pocilloDinamic.matriz_id = null;
      this.pocilloDinamic.matriz_name = null;
    },
    async changeEnsayoSelectedDinamic(pocillo) {
      pocillo.medio_id = null;
      pocillo.medio_prefix =	null;
      pocillo.medio_name =	null;
      pocillo.mix_id = null;
      pocillo.grupo_ensayo_mix = null;
      pocillo.ensayo_name = null;

      if (!pocillo.grupo_ensayo_id) {
        return;
      }
      const findEnsayo = this.getEnsayosPrograma(pocillo).find((ensa) => ensa.grupo_ensayo_id == pocillo.grupo_ensayo_id);
      if (findEnsayo) {
        let ensayoSelected = null;
        if (pocillo.is_control === 1) {
          /* const ensayoSelected = findEnsayo.ensayos_matriz[0];
          pocillo.ensayo_id = ensayoSelected.ensayo_id;
          pocillo.ensayo_easypcr_id = ensayoSelected.ensayo_easypcr_id;
          pocillo.ensayo_name = findEnsayo.name_ensayo; */
          return;
        }
        ensayoSelected = findEnsayo.ensayos_matriz.find((ensa) => ensa.matriz_id == pocillo.matriz_id);
        if (ensayoSelected) {
          pocillo.ensayo_id = ensayoSelected.ensayo_id;
          pocillo.ensayo_easypcr_id = ensayoSelected.ensayo_easypcr_id;
          pocillo.ensayo_name = findEnsayo.name_ensayo;
          const mixDefault = this.getMixesEnsayo(pocillo);
          console.log({ mixDefault });
          if (mixDefault.length === 1) {
            pocillo.mix_id = mixDefault[0].mix_id;
            await this.changeMixSelected(pocillo);
          }
          return;
        }
      }
      pocillo.grupo_ensayo_id = null;
      pocillo.ensayo_id = null;
      pocillo.ensayo_easypcr_id = null;
      pocillo.ensayo_name = null;
    },
    async changeMixSelectedDinamic(pocillo) {
      if (!pocillo.mix_id) {
        return;
      }

      let getMix = null;
      if (pocillo.is_control) {
        getMix = this.mixes_controles.find((mix) => parseInt(mix.id_programa) === parseInt(this.programaSelected)
            && parseInt(mix.mix_id) === parseInt(pocillo.mix_id));
      } else {
        getMix = this.mixes.find((mix) => mix.grupo_ensayo_id == pocillo.grupo_ensayo_id
            && mix.id_programa === this.programaSelected && mix.mix_id == pocillo.mix_id);
      }
      pocillo.medio_id = 0;
      if (getMix) {
        pocillo.mix_prefix = getMix.mixname;

        const medioDefault = this.getMediosEnsayo(pocillo);
        if (medioDefault.length && this.programaSelected == 2) {
          pocillo.medio_id = medioDefault[0].id;
          await this.changeMedioSelected(pocillo);
        }
        // pocillo.grupo_ensayo_mix = getMix.grupo_ensayo_mix;
        return;
      }
      pocillo.mix_id = null;
      pocillo.grupo_ensayo_mix = null;
    },
    async editPocillos() {
      console.log({ editPocillos: 'editPocillos' });
      console.log({ pocilloDinamic: this.pocilloDinamic });
      if (!this.pocillosSelected.length) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('analisis.warning'),
          text: this.$t('analisis.ningun_pocillo_seleccionado'),
        });
        return;
      }
      for await (const pocilloSelect of this.pocillosSelected) {
        const coordinate = structuredClone(pocilloSelect.coordinate);
        const position = structuredClone(pocilloSelect.position);
        if (this.pocilloDinamic.muestra_id !== null && this.pocilloDinamic.is_control === 0) {
          this.pocilloDinamic.descripcion = `sample_${coordinate}`;
        }
        await this.updatePocillo(pocilloSelect, this.pocilloDinamic);
        pocilloSelect.coordinate = coordinate;
        pocilloSelect.position = position;
      }
      if(this.programaSelected == 2){
        for (const pocilloSelect2 of this.pocillosSelected) {
          const position2 = pocilloSelect2.position;
          //console.warn({updatePocilloref: this.$refs});
          //console.warn({refss: `tree_select_${position2}`});
          //console.warn(this.$refs[`tree_select_${position2}`]);
          this.$refs[`tree_select_${position2}`][0].select(pocilloSelect2);
        }
      }
      await this.getFilesRequiredBitacoraManual();
      this.actionPocilloDinamic(this.getSampleTemplate());
      this.actionPocillosSelected([]);
    },
    async updatePocillo(target, src) {
      // console.log({target,src});
      const keysPocillo = Object.keys(target);
      for (let i = 0; i < keysPocillo.length; i++) {
        const keyPocillo = keysPocillo[i];
        target[keyPocillo] = (src.hasOwnProperty(keyPocillo) ? src[keyPocillo] : target[keyPocillo]);
      }
      // await Object.keys(target).forEach(k => target[k] = (src.hasOwnProperty(k) ? src[k] : target[k]));
    },
  },
};
</script>
<style lang="scss">
.vue-tree-min-width {
  min-width: 200px;
}
.table-vue-tree {
  overflow-x: auto;
  .v-data-table__wrapper {
    display: inline;
  }
}
</style>
<style lang="scss" scoped>
.v-data-table .v-select {
  max-width: 200px;
}
</style>
