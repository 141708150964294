import { render, staticRenderFns } from "./Tabla.vue?vue&type=template&id=147a74c2&scoped=true&"
import script from "./Tabla.vue?vue&type=script&lang=js&"
export * from "./Tabla.vue?vue&type=script&lang=js&"
import style0 from "./Tabla.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Tabla.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./Tabla.vue?vue&type=style&index=2&id=147a74c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147a74c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCol,VDataTable,VRow,VSelect,VSimpleCheckbox,VSimpleTable})
