<template>
    <div>
      <v-row class="">
        <v-col cols="12">
          <p class="mt2 mr-1 bold fs20 text-left">{{ $t('analisis.Resultados_analisis') }}</p>
          <p class="fs16 mt-3 mr-1 text-left"> {{ $t('analisis.Reviewyouranalysisresultsfor') }}
            <template v-if="tipoInterpretacion === 3">
              {{ $t('analisis.bitacora') }} - <strong># {{ bitacora_id }}</strong>
            </template>
            <template v-else>
              {{ $t('analisis.sin_bitacora') }}
            </template>
          </p>
        </v-col>
      </v-row>
      <v-row class="bg--normal__white border--radius__10">
        <v-col cols="12" class="text-right">
          <v-btn v-if="tipoInterpretacion === 3 && !publicado" class="c-btn c-btn--blue bold mr-5" outlined @click="publicarBitacora()" :disabled="false">
            {{ $t('analisis.publicar') }}
          </v-btn>
          <v-btn class="c-btn" color="#007AFF" outlined @click="generarPdf()">{{
              $t('analisis.generar_pdf') }}
          </v-btn>
          <v-btn class="c-btn ml-5" color="#007AFF" text @click="regresarAnalisis()">
            {{ $t('analisis.salir') }}
          </v-btn>
        </v-col>
        <v-col cols="12" class=" o-table-analisys c-table-analisys">
          <v-alert dense text  v-if="completedBitacora !== null"
                   :type="completedBitacora === 2 ? 'success' : (completedBitacora === 1 ? 'warning' : 'info') ">
            {{completedBitacora === 2 ? $t('analisis.bitacora_publicada') : (completedBitacora === 1 ? $t('analisis.bitacora_publicada_parcialmente') : $t('analisis.bitacora_publicada'))}}
          </v-alert>
          <v-alert dense text type="success" v-if="completedBitacora > 0">
            {{ $t('analisis.muestras_publicadas') }} <strong>{{
              $t('analisis.muestras_publicadas_txt1')
            }}</strong>
            {{ $t('analisis.muestras_publicadas_txt2') }} <strong>Txa-Lab</strong>
          </v-alert>
          <v-alert dense border="left" elevation="2"
                   v-for="(item, indexWarn) in warningsPublicar" v-if="item.type === 'WARN'" type="warning"
                   :key="indexWarn">
            {{showTextWarning(item)}}
          </v-alert>
          <template>
            <v-simple-table dense fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>
                    {{ $t('analisis.well') }}
                  </th>
                  <th>
                    {{ $t('analisis.codigo_muestra') }}
                  </th>
                  <th>
                    {{ $t('analisis.matriz') }}
                  </th>
                  <th>
                    {{ $t('analisis.ensayo') }}
                  </th>
                  <th>
                    {{ 'Mix' }}
                  </th>
                  <th>
                    {{ $t('analisis.medio') }}
                  </th>
                  <th>
                    {{ $t('analisis.categoria') }}
                  </th>
                  <th>
                    {{ $t('analisis.estado_muestra') }}
                  </th>
                  <th>
                    {{ $t('analisis.tipo_muestra') }}
                  </th>
                  <th>
                    <div>
                      <span>
                        {{ $t('analisis.resultado') }}
                      </span>
                      <v-switch
                          v-if="showSwitchEditResults()"
                          class="td_result--result-container-switch"
                          v-model="boolEditResults"
                          :label="$t('ensayos.editar')"
                          color="info"
                          hide-details
                      ></v-switch>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody class="tbody_results">
                  <tr v-for="(pocillo, index) in pocillos_interpretacion" :key="index">
                    <td class="text-start">
                      <v-simple-checkbox
                        v-show="informacion_bitacora ? (informacion_bitacora.ind_status_bitacora > 0) : false"
                        :value="pocillo.publish"
                        style="margin:0px;padding:0px"
                        :ripple="false"
                        color="#535353"
                        hide-details
                        @input="pocillo.publish = !pocillo.publish"
                      >
                      </v-simple-checkbox>
                      <span>
                          {{`${pocillo.coordinate} (${pocillo.position})`}}
                      </span>
                    </td>
                    <td class="text-start">
                      <span>
                        {{ pocillo.is_control === 0 ? pocillo.muestra_id  : pocillo.prefijo_muestra}}
                      </span>
                    </td>
                    <td class="text-start">
                      <span>
                        {{ pocillo.matriz_name ? $parent.transMatrices(pocillo.matriz_name) : '-' }}
                      </span>
                    </td>
                    <td class="text-start">
                      <span>
                        {{ getEnsayo(pocillo.grupo_ensayo_id) }}
                      </span>
                    </td>
                    <td class="text-start">
                      <span>
                        {{ pocillo.mix_prefix ? pocillo.mix_prefix : '-' }}
                      </span>
                    </td>
                    <td class="text-start">
                      <span>
                        {{ pocillo.medio_name ? pocillo.medio_name : '-' }}
                      </span>
                    </td>
                    <td class="text-start">
                      <span>
                        {{ formatBranch(pocillo.nivel_muestra_names) }}
                      </span>
                    </td>
                    <td class="text-start">
                      <span>
                        {{ getDeteriorado(pocillo.deteriorado) }}
                      </span>
                    </td>
                    <td class="text-start">
                      {{ pocillo.is_control === 0 ? getTxtRange(pocillo.txt_range) : '-' }}
                    </td>
                    <td class="text-start">
                      <show-resultados :ref="'showResultadosRef_'+pocillo.position" v-bind:dataPocillo="pocillo"></show-resultados>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn v-if="tipoInterpretacion === 3 && !publicado" class="c-btn c-btn--blue bold mr-5" outlined @click="publicarBitacora()" :disabled="false">
            {{ $t('analisis.publicar') }}
          </v-btn>
          <v-btn class="c-btn" color="#007AFF" outlined @click="generarPdf()">{{
              $t('analisis.generar_pdf') }}
          </v-btn>
          <v-btn class="c-btn ml-5" color="#007AFF" text @click="regresarAnalisis()">
            {{ $t('analisis.salir') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
</template>
<script>
import Swal from 'sweetalert2';
import { mapState, mapActions } from 'vuex';
import showResultados from './showResults.vue';

export default {
  components: {
    showResultados,
  },
  data() {
    return {
      warningsPublicar: [],
      completedBitacora: null,
    };
  },
  computed: {
    ...mapState('storeInterpretacion', [
      'equipos_laboratorio', 'isLab', 'archivos_requeridos', 'pocillos_interpretacion',
      'pocillos_bitacora', 'niveles_muestras', 'medios_ensayos', 'ensayos_software',
      'matrices', 'tipo_deteriorantes', 'controles', 'programa_id', 'tipoInterpretacion',
      'equipoLaboratorioSelected', 'bitacora_id', 'showEditResults', 'files_names_system', 'versionFile',
      'id_interpretacion','informacion_bitacora', 'editado', 'cambios', 'publicado',
    ]),
    getEnsayo() {
      return (grupo_ensayo_id) => {
        let ensayoTxt = '-';
        const findEnsayo = this.ensayos_software.find((ensa) => ensa.grupo_ensayo_id == grupo_ensayo_id);
        if (findEnsayo) {
          ensayoTxt = findEnsayo.name_ensayo;
        }
        return ensayoTxt;
      };
    },
    boolEditResults: {
      get() { return this.$store.state.storeInterpretacion.boolEditResults; },
      set(value) { this.actionBoolEditResults(value); },
    },
  },
  methods: {
    ...mapActions('storeInterpretacion', ['actionLoadingModule', 'actionBitacoraData',
      'actionResetBitacoraData', 'actionMaxFiles', 'actionTipoInterpretacion',
      'actionEquipoLaboratorioSelected', 'actionSelectedFiles', 'actionBitacora_id',
      'actionPocillos_interpretacion', 'actionPrograma_id', 'actionShowAnalisis', 'actionBoolEditResults',
      'actionFilesNamesSystem', 'actionVersionFile', 'actionProgramaSelected', 'actionInterpretecionId',
      'actionPublicado']),
    getTxtRange(txt_range) {
      if (txt_range === 'directa') {
        return this.$t('analisis.directa');
      }
      return txt_range || '-';
    },
    getDeteriorado(deteriorado) {
      switch (deteriorado) {
        case 'DETERIORADO': return this.$t('analisis.deteriorado'); break;
        case 'NO DETERIORADO': return this.$t('analisis.nodeteriorado'); break;
        default: return '-';
      }
    },
    formatBranch(branch) {
      if (branch === null) {
        return '-';
      }
      const route = branch;
      const routeParts = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
      let formattedRoute = '';
      for (const part of routeParts) {
        if (route.hasOwnProperty(part)) { formattedRoute += (part === 'A' ? '' : '/') + this.$parent.displayTextNiveles(route[part]); }
      }
      return formattedRoute;
    },
    publicarBitacora() {
      console.log({ publicarBitacora: 'publicarBitacora' });
      new Promise(async (resolve, reject) => {
        Swal.fire({
          title: this.$t('analisis.confirmar_publicar_resultados'),
          text: '',
          showDenyButton: true,
          icon: 'warning',
          confirmButtonText: this.$t('analisis.publicar_resultados'),
          denyButtonText: this.$t('analisis.cancelar'),
        }).then((result) => {
          console.log({ result });
          if (result.isConfirmed) {
            resolve(true);
          } else if (result.isDenied) {
            reject(false);
          }
        });
      }).then(async (responseConfirm) => {
        await this.sendBitacora()
          .then((response) => {
            console.log({ sendBitacora: response });
            this.warningsPublicar = response.data.data.warnings_errores_publish;
            this.completedBitacora = response.data.data.completedBitacora;
            this.sendToTop();
            this.actionPublicado(true);
          })
          .catch((error) => {
            console.error({ sendBitacoraPromiseErr: error });
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message,
            });
          }).finally(() => {
            this.actionLoadingModule();
          });
      }).catch(async (error) => {
        console.error({ getBitacora_confirm: error });
      });
    },
    generarPdf(){
      if(this.editado){
        this.generarPdfNew();
      } else {
        this.generarPdfOld();
      }
    },
    generarPdfNew(){
      Swal.fire({
        title: this.$t('analisis.changes_detected'),
        text: this.$t('analisis.change_reason'),
        input: 'text',
        inputPlaceholder: this.$t('analisis.change_reason'),
        showCancelButton: true,
        confirmButtonText: this.$t('analisis.btn_confirm'),
        cancelButtonText: this.$t('analisis.cancelar'),
        preConfirm: (inputValue) => {
          if (!inputValue) {
            Swal.showValidationMessage(this.$t('analisis.please_enter_reason'))
          }
          return inputValue; // Pass the input value
        }
      }).then((result) => {
        if (result.isConfirmed) {
          // If confirmed, execute the existing action
          axios.post(`${process.env.VUE_APP_URL_API}api/change-reporte-pdf`, {
            samples: this.pocillos_interpretacion,
            reason: result.value,
            id: this.id_interpretacion,
            cambios: this.cambios,
          }, {
            headers: {
              Authorization: `Bearer ${this.$ls.storage.token}`,
            },
          }).then((response) => {
            console.log({ response });
            const url = `${process.env.VUE_APP_API_CC}reporteSteriplex/${response.data.id}`;
            window.open(url, '_blank');
          }).catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message,
            });
          });
        }
      });

    },
    generarPdfOld() {
      console.log({ generarPdf: 'generarPdf' });
      if (this.id_interpretacion === null) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.$t('analisis.not_found_pdf'),
        });
        return;
      }
      const url = `${process.env.VUE_APP_API_CC}reporteSteriplex/${this.id_interpretacion}`;
      window.open(url, '_blank');
    },
    regresarAnalisis() {
      console.log({ regresarAnalisis: 'regresarAnalisis' });
      new Promise(async (resolve, reject) => {
        Swal.fire({
          title: this.$t('analisis.confirmar_regresar_analisis'),
          text: '',
          showDenyButton: true,
          icon: 'warning',
          confirmButtonText: this.$t('analisis.aceptar'),
          denyButtonText: this.$t('analisis.cancelar'),
        }).then((result) => {
          console.log({ result });
          if (result.isConfirmed) {
            resolve(true);
          } else if (result.isDenied) {
            reject(false);
          }
        });
      }).then(async (responseConfirm) => {
        this.actionBitacora_id(null);
        this.actionResetBitacoraData();
        this.actionPocillos_interpretacion([]);
        this.actionShowAnalisis(true);
        this.actionEquipoLaboratorioSelected(null);
        this.actionSelectedFiles([]);
        this.actionMaxFiles(0);
        this.actionFilesNamesSystem(null);
        this.actionVersionFile(null);
        this.actionProgramaSelected(null);
        this.actionInterpretecionId(null);
        this.warningsPublicar = [];
        this.completedBitacora = null;
      }).catch(async (error) => {
        console.error({ getBitacora_confirm: error });
      });
    },
    showSwitchEditResults() {
      return this.programa_id > 0 && this.programa_id !== 2 && this.showEditResults && this.tipoInterpretacion === 3;
    },
    async sendBitacora() {
      this.warningsPublicar = [];
      this.completedBitacora = null;
      this.actionLoadingModule({ text: `${this.$t('analisis.publicando_bitacora')}..`, active: true });
      return new Promise(async (resolve, reject) => {
        const dataSend = {
          bitacora_id: this.bitacora_id,
          equipo_id: this.equipoLaboratorioSelected.equipo_id,
          files_names_system: this.files_names_system,
          pocillos: JSON.stringify(this.pocillos_interpretacion),
          programa_id: this.programa_id,
          tipo_interpretacion: this.tipoInterpretacion,
          versionFile: this.versionFile,
        };

        await axios.post(`${process.env.VUE_APP_URL_API}api/publicacion-muestras-bitacora`, dataSend, {
          headers: {
            Authorization: `Bearer ${this.$ls.storage.token}`,
          },
        }).then((response) => {
          try {
            if (response.data.status === 'fail') {
              throw new Error(response.data.error);
            }
            resolve(response);
          } catch (error) {
            reject(error);
          }
        }).catch((error) => {
          try {
            throw new Error(error.response.data.error);
          } catch (error) {
            reject(error);
          }
        });
      });
    },
    showTextWarning(item) {
      let txtError = '';
      let txtMessageError = '';
      let reasonTrans = '';
      switch (item.reason) {
        case 'EMPTY_ENSAYO_MUESTRA':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'Ensayo de la muestra vacio.'}`;
          break;
        case 'NOT_FOUND_ASSAY':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se encontro el ensayo.'}`;
          break;
        case 'IS_PUBLISH':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'Muestra se encuentra publicada.'}`;
          break;
        case 'NOT_FOUND_SAMPLE_BITACORA':
          txtError = `WARN Pocillo: ${item.pocillo}, bitacoraId: ${item.bitacoraId}, Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se encontro la muestra en la bitacora.'}`;
          break;
        case 'CANNOT_FIND_PARAMETERS_MIX':
          // item.mixId, item.mixName
          txtError = `WARN Pocillo: ${item.pocillo}, bitacoraId: ${item.bitacoraId}, Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se encontraron la parametros del mix.'}`;
          break;
        case 'CANNOT_DELETED_RESULTS_PREVIOUS_ASSAY_STERIPLEX':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo eliminar los resultados previos.'}`;
          break;
        case 'CANNOT_DELETED_PARAMETERS_ASSAY_STERIPLEX':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo eliminar los parametros previos.'}`;
          break;
        case 'CANNOT_DELETED_PRERESULTS_ASSAY_STERIPLEX':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo eliminar los resultados previos del ensayo.'}`;
          break;
        case 'RESULTADOS_EMPTY':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'Sin resultados.'}`;
          break;
        case 'NOT_FOUND_PARAMETERS_ASSAY':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se encontraron los parametros del ensayo.'}`;
          break;
        case 'NOT_FOUND_PARAMETER':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se encontro el parametro'} ${item.parametroSoftware}.`;
          break;
        case 'NOT_ASING_PARAMETER_CUMPLE':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo asignar el resultado al parametro '} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_REACTIVE_PARAM_MUESTRA_OT':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo activar el parametro '} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_CREATE_PARAM_MUESTRA_OT':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo crear el parametro '} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_DELETED_PRERESULT':
          txtError = `WARN Pocillo: ${item.pocillo}:  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo eliminar el resultado previo del parametro '} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_CREATE_PRERESULT':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo crear el pre-resultado al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_FIND_PRERESULTS_COMBINATION':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se encontro la combinacion de resultados al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_ERROR':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo actualizaar la combinacion de resultados al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'INCONGRUENT_COMBINATION_RESULTS':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Combinacion de resultados incongruente al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R1':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo asignar reproceso rango 1 al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'ONLY_REPROCESS_R1':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Solo reproceso rango 1 al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_R2':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo asignar reproceso rango 2 al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'ONLY_REPROCESS_R2':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Solo reproceso rango 2 al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_UPDATE_PRERESULTS_COMBINATION_REPROCESS_ALL':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo mandar a  reproceso todos los rangos del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'REPROCESS_ALL':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Reproceso de toda la muestra del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'REPROCESS_RANGES':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Reproceso de los rangos del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'REPROCESS_GLOBAL':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Reproceso de la muestra global del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'WAITING_RESULTS_OTHER_RANGES':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Esperando resultados de los demas rangos del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_UPDATED_NO_COMBINATION_REPROCESO':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo actualizar a reproceso el parametro'} ${item.parametroSoftware}.`;
          break;
        case 'REPROCESS_PARAMETER':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'Reproceso al parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_DESTROY_RESULT_PREVIOUS':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudieron eliminar los resultados previos del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_PUBLISH_POSITIVE_RESULT':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No tienes permiso de publica resultado positivo del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'CANNOT_CREATE_RESULT':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, parametro: ${item.parametroSoftware}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo guardar el resultado del parametro'} ${item.parametroSoftware}.`;
          break;
        case 'NOT_FOUND_CONTROL_INTERNO_ASSAY':
          // grupoEnsayoId
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, REASON: ${item.reason}`;
          reasonTrans = `${'No se encontro el control interno del ensayo'}.`;
          break;
        case 'NOT_FOUND_MIXES_ASSAY':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, REASON: ${item.reason}`;
          reasonTrans = `${'No se encontraron los mix del ensayo'}.`;
          break;
        case 'CANNOT_UPDATE_PRERESULT_MIXES':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId}, REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo actualizar el resultado del ensayo'}.`;
          break;
        case 'CANNOT_UPDATE_TO_PUBLISH_RESULTS_ENSAYO':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo publicar el resultado del ensayo'}.`;
          break;
        case 'CANNOT_UPDATE_END_PUBLICATION_ENSAYO_MUESTRA':
          txtError = `WARN Pocillo: ${item.pocillo},  Ensayo_muestra_id: ${item.ensayoMuestraId}, muestra_id: ${item.muestraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo publicar el ensayo'}.`;
          break;
        case 'CANNOT_UPDATE_ESTATUS_BITACORA':
          txtError = `WARN Bitacora_id: ${item.bitacoraId} REASON: ${item.reason}`;
          reasonTrans = `${'No se pudo actualizar el estado de la bitacora'}.`;
          break;
      }
      txtMessageError = `${'WARN'} ${this.$t('analisis.well')} ${item.pocillo} - (${item.reason}) - ${this.$t('analisis.reason')}: ${this.$t(`analisis.${item.reason}`)}`;
      console.error({ txtError });
      return txtMessageError;
    },
    sendToTop() {
      window.scroll({top: 0, behavior: "smooth"})
    }
  },
};
</script>
<style lang="scss">
.tbody_results {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
